import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">General guidance</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Variations</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Labels</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Icon usage</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Danger button usage</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "general-guidance"
    }}>{`General guidance`}</h2>
    <p><em parentName="p">{`Buttons`}</em>{` express what action will occur when the user clicks or touches it. Buttons are used to initialize an action, either in the background or foreground of an experience.`}</p>
    <p>{`Buttons are used primarily on action items. Some examples include `}<strong parentName="p">{`Add`}</strong>{`, `}<strong parentName="p">{`Save`}</strong>{`, `}<strong parentName="p">{`Delete`}</strong>{`, and `}<strong parentName="p">{`Sign up`}</strong>{`. Each page can have one or two primary buttons. Any remaining calls-to-action should be represented as secondary buttons.`}</p>
    <p>{`Do not use buttons as navigational elements. Instead, use `}<a parentName="p" {...{
        "href": "/components/link"
      }}>{`links`}</a>{` when the desired action is to take the user to a new page.`}</p>
    <h2 {...{
      "id": "variations"
    }}>{`Variations`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Button type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Purpose`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Primary`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For the principal call to action on the page.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Secondary`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For secondary actions on each page.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Button with icon`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`When words are not enough, icons can be used in buttons to better communicate what the button does. Icons are always paired with text.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Disabled button`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Use when the user cannot proceed until an input is collected.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Set of buttons`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`When an action required by the user has more than one option, always use a a negative action button (secondary) paired with a positive action button (primary) in that order. Negative action buttons will be on the left; positive action buttons on the right. When these two types of buttons are paired in the correct order, they will automatically space themselves apart.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Small button`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Use when there is not enough vertical space for a regular sized button.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Ghost button`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`When an action does not require primary dominance on the page.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Danger button`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`When an action has potentially destructive effects on the user’s data (delete, remove, etc).`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "labels"
    }}>{`Labels`}</h2>
    <p>{`Button labels should clearly indicate the action of the button. Use active verbs, such as `}<strong parentName="p">{`Add`}</strong>{` or `}<strong parentName="p">{`Delete`}</strong>{`. Use sentence-style capitalization (only the first word in a phrase and any proper nouns capitalized) and no more than three words for button labels.`}</p>
    <p>{`For sets of buttons, use specific labels, such as `}<strong parentName="p">{`Save`}</strong>{` or `}<strong parentName="p">{`Discard`}</strong>{`, instead of using `}<strong parentName="p">{`OK`}</strong>{` and `}<strong parentName="p">{`Cancel`}</strong>{`. This is particularly helpful when the user is confirming an action.`}</p>
    <p>{`For consistency, see `}<a parentName="p" {...{
        "href": "/guidelines/content/glossary"
      }}>{`labels and idioms`}</a>{` for the approved list of action labels.`}</p>
    <h2 {...{
      "id": "icon-usage"
    }}>{`Icon usage`}</h2>
    <ul>
      <li parentName="ul">{`Use glyphs (16px) within buttons.`}</li>
      <li parentName="ul">{`Glyphs are distinguished by their solid shape and knocked-out details.`}</li>
      <li parentName="ul">{`Glyphs should always appear to the right of the text.`}</li>
      <li parentName="ul">{`Glyphs used in buttons must be directly related to the action that the user is taking.`}</li>
      <li parentName="ul">{`Glyphs must be the same color value as the text within a button.`}</li>
      <li parentName="ul">{`Ghost buttons require a glyph icon.`}</li>
    </ul>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "28.260869565217394%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "button with glyph",
        "title": "button with glyph",
        "src": "/static/d08b0e22f8e12982853e2afff8e5c535/fb070/button-usage-1.png",
        "srcSet": ["/static/d08b0e22f8e12982853e2afff8e5c535/d6747/button-usage-1.png 288w", "/static/d08b0e22f8e12982853e2afff8e5c535/09548/button-usage-1.png 576w", "/static/d08b0e22f8e12982853e2afff8e5c535/fb070/button-usage-1.png 1152w", "/static/d08b0e22f8e12982853e2afff8e5c535/c3e47/button-usage-1.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h2 {...{
      "id": "danger-button-usage"
    }}>{`Danger button usage`}</h2>
    <p>{`Danger buttons have a different visual style to inform users of potentially destructive actions they are about to take. If using the danger button as a standalone, we recommend styling it as a `}<strong parentName="p">{`secondary button`}</strong>{`. Within a set, the danger button should be styled as a `}<strong parentName="p">{`primary button.`}</strong></p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "49.18478260869565%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "danger button set in modal",
        "title": "danger button set in modal",
        "src": "/static/68954cf3f3a42c833af2376d2241348e/fb070/button-usage-2.png",
        "srcSet": ["/static/68954cf3f3a42c833af2376d2241348e/d6747/button-usage-2.png 288w", "/static/68954cf3f3a42c833af2376d2241348e/09548/button-usage-2.png 576w", "/static/68954cf3f3a42c833af2376d2241348e/fb070/button-usage-2.png 1152w", "/static/68954cf3f3a42c833af2376d2241348e/c3e47/button-usage-2.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      